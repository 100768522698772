<template>
 <div class="s_bind_account_role_pop_tb">
   <p class="s-bind-account-role-pop-tb-title">淘宝账号审核必须提交三张图：信誉登记; 实名认证; 花呗; 可通过手机拍张（信息必须清晰可见）也可使用电脑截图，图片如下：</p>
   <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/tb1.png" alt="" />
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/tb2.png" alt=""/>
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/tb3.png" alt=""/>
    </div> 
    <p class="s-bind-account-role-pop-tb-title">手机淘宝支付宝扫码截图上传提供资料：</p>
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/tb4.jpg" alt="" />
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/tb5.jpg" alt="" />
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/tb6.jpg" alt=""/>
    </div> 
   <p class="s-bind-account-role-pop-tb-title">淘宝审核要求：</p>
   <ul class="s-bind-account-role-pop-tb-list">
     <li class="s-bind-account-role-pop-tb-list-item">1、绑定时要求填写的淘宝用户名（非登录的手机号及邮箱号）就是该淘宝的用户名（旺旺号），<span>（必须要与实名认证中的一致）</span> </li>
     <li class="s-bind-account-role-pop-tb-list-item">2、信誉等级达到2星及以上，账号与支付宝注册满6个月，账号购买量稳定（注册大于等于半年购买量或注册时间小于六个月且过买量过高的均不能通过审核） </li>
     <li class="s-bind-account-role-pop-tb-list-item">3、收货信息（姓名，电话，地址）必须真实有效且不能重复使用，地址必须具体的**路，**小区，门牌号 </li>
     <li class="s-bind-account-role-pop-tb-list-item">4，淘宝的收货地址必须真实有效且不能重复使用</li>
     <li class="s-bind-account-role-pop-tb-list-item">5、一个账号最多个绑定三个淘宝买好，未通过审核的可以自己修改后重新提交，有账号被冻结不能再绑定其他账号替换 </li>
     <li class="s-bind-account-role-pop-tb-list-item">6、绑定的账号实名认证的身份证必须与平台注册时绑定的身份证一致，三个淘宝买好实名认证的身份证必须一致</li>
     <li class="s-bind-account-role-pop-tb-list-item">7、做任务的淘宝买号不能使用淘宝卖家号，开通过店铺的淘宝账号不能通过审核</li>
     <li class="s-bind-account-role-pop-tb-list-item">8、账号属性信息（性别，出生年月日）必须与实名认证的身份证一致，如淘宝账号信誉等级提升可以联系在线客服处理</li>
   </ul>
 </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang='scss'>
.s_bind_account_role_pop_tb {
  flex: 1;
  overflow: auto;
  padding: 10px 12px;
  .s-bind-account-role-pop-tb-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: $black;
    padding-bottom: 10px;
  }
  .s-bind-account-role-pop-tb-img {
    img {
      width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
  .s-bind-account-role-pop-tb-list {
    font-size: 14px;
    line-height: 22px;
    color: grey;
    .s-bind-account-role-pop-tb-list-item {
      padding: 5px 0;
      span {
        color: red;
        font-weight: bold;
      }
    }
  }
}
</style>