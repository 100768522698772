<template>
  <div class="v_bind_account g-flex-column">
    <head-back :backRouterName="backRouterName">
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>{{titleName}}</span>
      </template>
    </head-back>
    
    <div class="v-bind-account-container">
      <!-- 提示 -->
      <div class="v-bind-account-top">
        <p class="v-bind-account-top-tips"> 
          账号审核时间为
          <span>周一至周五9:00-18:00</span>,账号提交后5个工作日内完成审核，如遇节假日顺延，审核工作人工进行,用户请耐心等待，新手务必查看下方审核要求! 
        </p>
        <div class="v-bind-account-top-btn">
          <operation-button :isPadding="false"  @clickItem="emitClickItem" :buttonObj="buttonObj"/>
        </div>
      </div>

      <!-- 账户信息 -->
      <div class="v-bind-account-middle">
        <p class="v-bind-account-middle-title">账户信息</p>
        <ul class="v-bind-account-middle-card">
          <!-- 淘宝账号 -->
          <li class="v-bind-account-middle-item"  v-show="routeType === '0'">
            <van-field v-model="form.account" label="淘宝账号：" placeholder="请输入淘宝账号" clearable/>
          </li>
          <!-- 京东账号 -->
          <li class="v-bind-account-middle-item"  v-show="routeType === '1'">
            <van-field v-model="form.account" label="京东账号：" placeholder="请输入京东账号" clearable/>
          </li>
          <!-- 拼多多账号 -->
          <li class="v-bind-account-middle-item"  v-show="routeType === '2'">
            <van-field v-model="form.account" label="拼多多账号：" placeholder="请输入拼多多账号" clearable/>
          </li>

          <li class="v-bind-account-middle-item">
            <div class="v-bind-account-item-box  g-flex-align-center">
              <span class="v-bind-account-item-label">性别:</span>
              <van-radio-group v-model="form.sex" direction="horizontal" checked-color="#F9436B">
              <van-radio :name=1>男</van-radio>
              <van-radio :name=2>女</van-radio>
            </van-radio-group>
            </div>
          </li>

           <li class="v-bind-account-middle-item">
             <van-cell title="出生日期:" class="v-change-info-form-item-date-cell" is-link  :value="form.birthday ? formatDate(form.birthday, 'yyyy-MM-dd') : this.placeholderdateValue " @click="dateClick"/>
          </li>

           <li class="v-bind-account-middle-item">
            <van-field v-model="form.name" label="收货人姓名：" placeholder="请输入收货人姓名" clearable/>
          </li>
           <li class="v-bind-account-middle-item">
            <van-field v-model="form.mobile" label="收货手机号：" placeholder="请输入手机号" clearable/>
          </li>
           <li class="v-bind-account-middle-item v-bind-item-address-cell">
            <van-cell title="地区选择:" class="v-change-info-form-item-date-cell" is-link  :value="form.region ? form.region : addressValue" @click="addressClick"/>
          </li>
          <li class="v-bind-account-middle-item">
            <van-field v-model="form.address" label="详细地址：" placeholder="请输入详细地址" clearable/>
          </li>
        </ul>
      </div>

      <!-- 账户信息 -->
      <div class="v-bind-account-bottom v-bind-account-middle">
        <p class="v-bind-account-middle-title">其他信息</p>
        <ul class="v-bind-account-middle-card">
          <!-- 淘宝信誉等级 -->
           <li class="v-bind-account-middle-item v-bind-item-address-cell" v-show="routeType === '0'">
            <van-cell title="淘宝信誉等级:" class="v-change-info-form-item-date-cell" is-link  :value="form.xinyuTitle ? form.xinyuTitle: '请选择淘宝信誉等级'" @click="levelClick"/>
          </li>
          <!-- 京东信誉等级 -->
          <li class="v-bind-account-middle-item v-bind-item-address-cell" v-show="routeType === '1'">
            <van-cell title="京东信誉等级:" class="v-change-info-form-item-date-cell" is-link  :value="form.xinyuTitle ? form.xinyuTitle: '请选择京东信誉等级'" @click="levelClick"/>
          </li>
          <!-- 花呗 -->
          <li class="v-bind-account-middle-item" v-show="routeType === '0'">
            <div class="v-bind-account-item-box  g-flex-align-center">
              <span class="v-bind-account-item-label">已开通花呗:</span>
              <van-radio-group v-model="form.credit_pay" direction="horizontal" checked-color="#F9436B">
                <van-radio :name=1>已开通</van-radio>
                <van-radio :name=0>未开通</van-radio>
              </van-radio-group>
            </div>
          </li>
          <!-- 白条 -->
          <li class="v-bind-account-middle-item"  v-show="routeType === '1'">
            <div class="v-bind-account-item-box  g-flex-align-center">
              <span class="v-bind-account-item-label">已开通白条:</span>
              <van-radio-group v-model="form.credit_pay" direction="horizontal" checked-color="#F9436B">
                <van-radio :name=1>已开通</van-radio>
                <van-radio :name=0>未开通</van-radio>
              </van-radio-group>
            </div>
          </li>
          <!-- 上传图片 -->
          <li class="v-bind-account-middle-item g-flex-align-center v-bind-account-middle-upload">
            <bind-upload uploadText="实名认证" :imgUrl="form.real_img" :uploadType="1" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '0'"></bind-upload>
            <bind-upload uploadText="信用等级" :imgUrl="form.xinyu_img" :uploadType="2" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '0'"></bind-upload>
            <bind-upload uploadText="花呗截图" :imgUrl="form.credit_pay_img" :uploadType="3" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '0'"></bind-upload>

            <bind-upload uploadText="实名认证" :imgUrl="form.real_img" :uploadType="4" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '1'"></bind-upload>
            <bind-upload uploadText="信誉等级" :imgUrl="form.xinyu_img" :uploadType="5" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '1'"></bind-upload>
            <bind-upload uploadText="白条截图" :imgUrl="form.credit_pay_img" :uploadType="6" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '1'"></bind-upload>
            <bind-upload uploadText="账户截图" :imgUrl="form.account_img" :uploadType="7" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '1'"></bind-upload>
          
            <bind-upload uploadText="账户截图" :imgUrl="form.account_img" :uploadType="8" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '2'"></bind-upload>
            <bind-upload uploadText="微信/qq截图" :imgUrl="form.real_img" :uploadType="9" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg" v-show="routeType === '2'"></bind-upload>
          </li>

          <li class="v-bind-account-middle-item"  v-if="form.remark">
            <div class="v-bind-account-item-box  g-flex-align-center">
              <span class="v-bind-account-item-label v-bind-account-item-label-liyou">审核不通过理由:</span>
              <van-field class="g-flex-1" v-model="form.remark" rows="2" autosize type="textarea" placeholder="请输入留言"/>
            </div>
          </li>
        </ul>
      </div>

    <!-- 提交按钮 -->
      <div class="v-bind-account-submit" v-if="form.status === 0">
        <operation-button :isPadding="false" @clickItem="emitClickItem" :buttonObj="buttonObjSecond"></operation-button>
      </div>
    </div>

    <!-- 选择地址 -->
    <change-address ref="ChangeAddress" @emitAddress="emitAddress"/>

    <!-- 信誉等级 -->
    <bind-account-level-pop ref="BindAccountLevelPop" @emitLevelPicker="emitLevelPicker"/>
    
    <!-- 选择日期 -->
    <change-date ref="ChangeDate" @emitDatePicker="emitDatePicker"/>

    <!-- 审核规则 -->
    <bind-account-role-pop ref="BindAccountRolePop"/>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import BindUpload from '../../components/BindUpload.vue'
  import ChangeAddress from '../../components/ChangeAddress.vue'
  import BindAccountLevelPop from './BindAccountLevelPop.vue'
  import ChangeDate from '../../components/ChangeDate.vue'
  import { apiSaveAccount, apiTaskConfig, apiGetAccountInfo } from '@/utils/api.js'
  import { formatDate } from '../../utils/index.js'
  import BindAccountRolePop from './BindAccountRolePop.vue'
  export default {
    components: { HeadBack, OperationButton, BindUpload, ChangeAddress, BindAccountLevelPop, ChangeDate, BindAccountRolePop },
    data() {
      return {
        placeholderdateValue: '请选择出生日期',
        addressValue: '请选择区域',
        levelValue: this.$route.params.type === '0'? '请选择淘宝信誉等级': '请选择京东信誉等级',
        routeType: this.$route.params.type, // 是淘宝还是京东还是拼多多
        isEdit: this.$route.params.id, //是否编辑
        accountId: this.$route.params.id, //账号id
        titleName: '',
        backRouterName: '',

        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius c-btn-border-small',
          title: '查看审核规则',
          event: 'seeRoles'
        },

        form: {
          status: 0, //0 审核不通过(新增) 1 审核中 2审核成功
          platform_id: this.$route.params.platid,
          account: '',
          birthday: '',
          name: '',
          mobile: '',
          province: '',
          city: '',
          area: '',
          sex: 1,
          region: '',
          address: '', //详细地址
          xinyu: '', //信誉
          xinyuTitle: '',
          credit_pay: this.$route.params.type == '2' ? 0 : 1, //花呗白条开通  { name: '没开', value: 0 },{ name: '开通', value: 1 }
          credit_pay_img: '', //信用截图
          real_img: '', //实名截图
          xinyu_img: '', //信誉截图
          account_img: '' //账号截图 
        },

        buttonObjSecond: {
          claSS: 'c-btn-default',
          title: '提交',
          event: 'submitEvent'
        },
        // 信誉等级
        columns: [],
      }
    },
    async created() {
      if(this.routeType === '0') {
        if(this.isEdit == '0') {
          this.titleName = '绑定淘宝账号'
        }else {
          this.titleName = '修改淘宝账号'
        }
      } else if(this.routeType === '1') {
        if(this.isEdit == '0') {
          this.titleName = '绑定京东账号'
        }else {
          this.titleName = '修改京东账号'
        }
      } else {
        if(this.isEdit == '0') {
          this.titleName = '绑定拼多多账号'
        }else {
          this.titleName = '修改拼多多账号'
        }
      }
      
      await this.apiTaskConfigHandel()
      if(this.accountId != 0) await this.apiGetAccountInfoHandel()
    },
    methods: {
      formatDate,

      // 编辑进来的要获取信息
      async apiGetAccountInfoHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetAccountInfo({ id: this.accountId})
        if(!success) return
        this.form = data.data
        let obj = this.columns.find(item => item.id === data.data.xinyu)
        if(obj) this.form.xinyuTitle = obj.title
        console.log(data)
      },

      // 获取任务配置 code: 'jd_xinyu'  'xinyu'
      async apiTaskConfigHandel() {
        this.$global.loadingShow = true
        let code = this.form.platform_id == 1 ? 'xinyu' : 'jd_xinyu'
        const { success, data } = await apiTaskConfig({ code })
        if(!success) return
        console.log(data)
        this.columns = data.list
      },

      // 日期点击
      dateClick() {
        this.$refs.ChangeDate.onShow(true, this.form.birthday)
      },

      // 日期选择
      emitDatePicker(time) {
        this.form.birthday = parseInt(time / 1000) 
      },

      // 保存账号
      async apiSaveAccountHandel() {
        this.$global.loadingShow = true
        this.form.account = this.form.account.replace(/\s*/g,"")
        const { success, data } = await apiSaveAccount(this.form)
        if(!success) return
        this.$toast(data.msg)
        setTimeout(() => {
          this.$router.go(-1)
        }, 500);
        console.log(data)
      },

      // 查看规则
      seeRoles() {
        this.$refs.BindAccountRolePop.onShow()
        console.log('查看审核规则')
      },
      // 提交
      submitEvent() {
        if(!this.form.account) return this.$toast('请输入账号')
        if(!this.form.birthday) return this.$toast('请选择出生日期')
        if(!this.form.name) return this.$toast('请输入收货人姓名')
        if(!this.form.mobile) return this.$toast('请输入收货人手机号')
        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('收货人手机号码有误，请重试'); 
        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('收货人手机号码有误，请重试')
        if(!this.form.region) return this.$toast('请选择地区')
        if(!this.form.address) return this.$toast('请输入详细地址')
        if(this.routeType == '0' || this.routeType == '1') {
          if(!this.form.xinyu) return this.$toast('请选择信誉等级')
          if(!this.form.xinyu_img) return this.$toast('请上传信誉截图')
          if(this.form.credit_pay) {
            if(!this.form.credit_pay_img) return this.$toast('请上传白条/花呗截图')
          }
        }
        if(this.routeType == '1' || this.routeType == '2') {
          if(!this.form.account_img) return this.$toast('请上传用户名/账户截图')
        }
        if(!this.form.real_img) return this.$toast('请上传实名/微信截图')
        console.log('提交')
        this.apiSaveAccountHandel()
      },
      // 按钮触发
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },

      // 点击地址弹出
      addressClick() {
        this.$refs.ChangeAddress.onShow(true, this.form.areaCode)
      },

      // 地址选择触发
      emitAddress(list) {
        console.log(list)
        this.form.region = ''
        this.form.areaCode = list[list.length-1].code
        this.form.province = list[0].code
        this.form.city = list[1].code
        this.form.area = list[2].code
        this.form.region = list[0].name + '-' + list[1].name +  '-' + list[2].name
      },
      
      // 点击信誉等级弹出
      levelClick() {
        this.$refs.BindAccountLevelPop.onShow(true, this.columns, this.form.xinyu)
      },

      // 信誉等级触发
      emitLevelPicker(val) {
        this.form.xinyu = val.id
        this.form.xinyuTitle = val.title
      },

      // 上传成功触发
      emitUploadSuccess(obj) {
        if(obj.type == 1 || obj.type == 4 || obj.type == 9) {
          this.form.real_img = obj.url
        }else if(obj.type == 2 || obj.type == 5) {
          this.form.xinyu_img = obj.url
        }else if(obj.type == 3 || obj.type == 6) {
          this.form.credit_pay_img = obj.url
        }else if(obj.type == 7 || obj.type == 8) {
          this.form.account_img = obj.url
        }
        console.log(obj)
      },

      // 删除上传的文件
      emitDeleteUploadImg(obj) {
        if(obj.type == 1 || obj.type == 4 || obj.type == 9) {
          this.form.real_img = ''
        }else if(obj.type == 2 || obj.type == 5) {
          this.form.xinyu_img = ''
        }else if(obj.type == 3 || obj.type == 6) {
          this.form.credit_pay_img = ''
        }else if(obj.type == 7 || obj.type == 8) {
          this.form.account_img = ''
        }
        console.log(obj)
      }
    }
  }
</script>

<style lang="scss">
.v_bind_account {
  height: 100%;
  background: #F1F1F1;
  .v-bind-account-container {
    flex: 1;
    overflow: auto;
    .v-bind-account-top {
      padding: 18px 20px;
      .v-bind-account-top-tips {
        color: #656565;
        font-size: 13px;
        letter-spacing: 1;
        text-align: justify;
        line-height: 18px;
        span {
          color: $main_color;
        }
      }
      .v-bind-account-top-btn {
        padding: 12px 80px;
      }
    }

    .v-bind-account-middle {
      padding-top: 10px;
      .v-bind-account-middle-title {
        font-size: 14px;
        color: #000000;
        font-weight: bold;
        padding-left: 32px;
        padding-bottom: 10px;
      }
      .v-bind-account-middle-card {
        padding-top: 6px;
        background: $white;
        .v-bind-account-middle-item {
          .v-bind-account-item-box {
            padding: 10px 16px;
            .v-bind-account-item-label {
              flex: 1;
              color: #646566;
              text-align: left;
              font-size: 14px;
              &.v-bind-account-item-label-liyou {
                flex: 1;
              }
            }
            .van-radio-group {
              display: flex;
              justify-content: flex-end;
              flex: 2;
              font-size: 14px;
            }
          }
          .van-cell {
            .van-field__control {
              text-align: right;
            }
            .van-cell__title  {
              margin-right: 0;
              flex: 1;
              color: #646566; 
            }
            .van-cell__value {
              flex: 2;
              .van-field__control {
                color: #969799;
              }
            }
            &.v-bind-item-address-cell {
              .van-cell__value {
                padding-left: 10px;
              }
            }
          }
          &.v-bind-account-middle-upload {
            margin-top: 20px;
            padding-bottom: 30px;
          }
        }
      }
    }
    .v-bind-account-submit {
      padding: 25px 10px 12px 10px;
    }
  }
}
</style>