<template>
 <div class="s_bind_account_role_pop_tb">
    <p class="s-bind-account-role-pop-tb-title">拼多多账号审核规则：必须提交两张图片：拼多多账号截图；微信/QQ截图；通过手机截图，提交如下两张截图：</p>
    <p>1、拼多多账号截图（微信注册）</p>
    <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/pdd1.png" alt="" />
    </div> 
    <p class="s-bind-account-role-pop-tb-title">2、拼多多账号截图（QQ注册）</p>
    <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/pdd2.png" alt=""/>
    </div> 
 </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang='scss'>
.s_bind_account_role_pop_tb {
  flex: 1;
  overflow: auto;
  padding: 10px 12px;
  .s-bind-account-role-pop-tb-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: $black;
    padding-bottom: 10px;
  }
  .s-bind-account-role-pop-tb-img {
    img {
      width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
  .s-bind-account-role-pop-tb-list {
    font-size: 14px;
    line-height: 22px;
    color: grey;
    .s-bind-account-role-pop-tb-list-item {
      padding: 5px 0;
      span {
        color: red;
        font-weight: bold;
      }
    }
  }
}
</style>