<template>
  <div class="s_bind_account_level_taobao_pop">
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
       <van-picker
        title=""
        value-key="title"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        :default-index="defaultIndex"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        columns: [],
        defaultIndex: 1
      }
    },
    methods: {
      onShow(flag, list, defaultid = '') {
        this.columns = list
        if(defaultid) {
          this.columns.map(item => item.id).indexOf(defaultid)
        }
        this.show = flag
      },
      onConfirm(val) {
        this.$emit('emitLevelPicker', val)
        this.show = false
      },
      onCancel() {
        this.show = false
      }
    }
  }
</script>

<style lang="sass" scoped>

</style>