<template>
  <div class="c_bind_upload g-flex-justify-center g-flex-align-center">
    <van-uploader :before-read="beforeRead" :after-read="afterRead" v-show="!userAvator">
      <div class="c-bind-upload-box g-flex-column g-flex-align-center g-flex-justify-center">
        <i class="iconfont icon-xiangji1"></i>
        <span class="c-bind-upload-title">{{uploadText}}</span>
      </div>
    </van-uploader>

    <div class="c-bind-upload-image" v-show="userAvator">
      <img :src="userAvator" />
      <div class="c-bind-upload-image-close" @click="deleteUploadImg">
        <i class="iconfont icon-guanbi1"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { apiUpLoadHeadImg } from '../utils/api.js'
  export default {
    props: {
      imgUrl: {
        type: String,
        default() {
          return ''
        }
      },
      uploadText: {
        type: String,
        default() {
          return '上传图片'
        }
      },
      uploadType: {
        type: Number,
        default() {
          return 0
        }
      }
    },
    data() {
      return {
        limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],
        userAvator: '',
      }
    },
    methods: {
      // 上传之前
      beforeRead(file) {
        if(this.limitPng.indexOf(file.type) === -1) {
          this.$toast('请上传jpeg、jpg、png格式的图片')
          return false
        }
        return true
      },

      // 上传
      async afterRead(file) {
        // formdata提交
        let formData = new FormData()
        formData.append('file', file.file)
        this.$global.loadingShow = true
        const { success, data } = await apiUpLoadHeadImg(formData)
        if(!success) return false
        this.userAvator = data.url
        this.$toast(data.msg)
        this.$emit('emitUploadSuccess', { url: this.userAvator, type: this.uploadType })
      },

      // 删除
      deleteUploadImg() {
        this.userAvator = ''
        this.$emit('emitDeleteUploadImg', { type: this.uploadType })
      }
    },
    watch: {
      imgUrl: {
        handler(nval) {
          if(nval) {
            this.userAvator = nval
          } 
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">
.c_bind_upload {
  flex: 1;
  .van-uploader {
    .c-bind-upload-box {
      min-width: 80px;
      min-height: 80px;
      border-radius: 3px;
      border: 1px dashed #AAAAAA;
      .iconfont {
        color: #8F8F8F;
        font-size: 24px;
      }
      .c-bind-upload-title {
        padding-top: 4px;
        font-size: 14px;
        color: #8F8F8F;
      }
    }
  }
  .c-bind-upload-image {
    position: relative;
    .c-bind-upload-image-close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px;
      transform: translateX(50%) translateY(-50%);
      .iconfont {
        font-size: 22px;
      }
    }
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
}
</style>