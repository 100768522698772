<template>
 <div class="s_bind_account_role_pop_tb">
   <p class="s-bind-account-role-pop-tb-title">京东账号是否实名与开通白条需要提供两张图：实名认证; 白条; 可通过手机拍张（信息必须清晰可见）也可使用电脑截图，图片如下：</p>
   <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/jd1.png" alt="" />
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
     <img src="../../assets/img/jd2.png" alt=""/>
    </div> 
    <p class="s-bind-account-role-pop-tb-title">1，白条截图</p>
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/jd3.png" alt=""/>
    </div> 
    <p>2、进入“我的”页面截图提交</p>
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/jd4.png" alt="" />
    </div> 
    <div class="s-bind-account-role-pop-tb-img">
      <img src="../../assets/img/jd5.png" alt="" />
    </div> 
   <p class="s-bind-account-role-pop-tb-title">需要与提交的身份证信息一致, 实名审核注意事项：</p>
   <ul class="s-bind-account-role-pop-tb-list">
     <li class="s-bind-account-role-pop-tb-list-item">1、一个身份证只能认证一个京东号，账号属性（性别、出生年月）必须与实名认证的身份证一致。 </li>
     <li class="s-bind-account-role-pop-tb-list-item">2、实名账号任务优先派发。 </li>
     <li class="s-bind-account-role-pop-tb-list-item">3、白条未开通不影响接任务，开通白条属于专属条件只有部分任务要求开通白条的买好才能接。 </li>
     <li class="s-bind-account-role-pop-tb-list-item">4，截图错误或截图模糊无法核对信息的不能通过审核，请按要求提供正确的白条截图。</li>
   </ul>
 </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang='scss'>
.s_bind_account_role_pop_tb {
  flex: 1;
  overflow: auto;
  padding: 10px 12px;
  .s-bind-account-role-pop-tb-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: $black;
    padding-bottom: 10px;
  }
  .s-bind-account-role-pop-tb-img {
    img {
      width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
  .s-bind-account-role-pop-tb-list {
    font-size: 14px;
    line-height: 22px;
    color: grey;
    .s-bind-account-role-pop-tb-list-item {
      padding: 5px 0;
      span {
        color: red;
        font-weight: bold;
      }
    }
  }
}
</style>