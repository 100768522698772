<template>
  <div class="s_bind_account_role_pop">
    <van-popup v-model="show" position="right" :style="{width:'100%',height:'100%'}" :safe-area-inset-bottom="true" overlay-class="s-bind-account-role-pop-overlay" class="s-bind-account-role-pop-container">
      <div class="s-bind-account-role-pop-container">
        <head-back :isRouterBack="false" @emitHeadBack="onCancel">
          <template v-slot:returnText>
            <span>返回</span>
          </template>
          <template>
            <span>{{title}}</span>
          </template>
        </head-back>

        <BindAccountRolePopTB v-if="routeType == 0"/>
        <BindAccountRolePopJD v-if="routeType == 1"/>
        <BindAccountRolePopPDD v-if="routeType == 2"/>
      </div>

    </van-popup>
  </div>
</template>

<script>
  import HeadBack from '@/components/HeadBack.vue'
  import BindAccountRolePopTB from './BindAccountRolePopTB.vue'
  import BindAccountRolePopJD from './BindAccountRolePopJD.vue'
  import BindAccountRolePopPDD from './BindAccountRolePopPDD.vue'
  export default {
    components: { HeadBack, BindAccountRolePopTB, BindAccountRolePopJD, BindAccountRolePopPDD }, 
    data() {
      return {
        routeType: this.$route.params.type, // 是淘宝还是京东还是拼多多 0 淘宝 1京东 2拼多多
        title: '审核规则',
        show: false,
        form: {
          text: ''
        }
      }
    },
    methods: {
      onShow(text) {
        this.form.text = text
        this.show = true
      },
      onCancel() {
        this.show = false
      },
    }
  }
</script>

<style lang="scss">
.s_bind_account_role_pop {
  .s-bind-account-role-pop-overlay {
    background-color: transparent;
  }
  .s-bind-account-role-pop-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

</style>